@import url('https://fonts.googleapis.com/css?family=Space+Mono:700&subset=latin-ext');

:root {
  --color-yellow-dark: #d1b00c;
  --color-purple-bright: #c20097;
  --color-purple-dark: #750c5e;
  --color-green-bright: #0e8f8b;
  --color-green-dark: #044f4d;
  --color-yellow-bright: #ffd507;
}

html {
  box-sizing: border-box;
}

body {
  background-color: var(--color-yellow-dark);
  color:white;
  font-family: 'Space Mono', monospace;
  font-size: 1em;
  margin: 0;
}

a {
  text-decoration: none;
}

.wrapper {
  display: grid;
  grid-template-rows: 50px 1fr 1fr;
  grid-template-columns: 50px 1fr 1fr;
  grid-template-areas:
          "   . tran bran"
          "stab cont cont"
          "ctab cont cont";
  height: 100vh;
  max-width: 100vw;
}

.transport {
  grid-area: tran;
}

.branding {
  grid-area: bran;
  text-align: end;
  margin-right: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.branding img {
  height: 100%;
}

.tab {
  text-orientation: sideways;
  writing-mode: vertical-lr;
  height: 100%;
  text-align:center;
  margin-left: auto;
}
.tab a {
  color: white;
}
.tab a:hover {
  color: var(--color-purple-bright);
}

.panel_title {
  color: var(--color-purple-bright);
}

.sequencer {
  grid-area: stab;
}

.controls {
  grid-area: ctab;
}

.tab_selected {
  border-right: 5px solid var(--color-purple-bright);
}
.tab_selected a {
  color: var(--color-purple-bright);
}

.content {
  grid-area: cont;
  grid-column-end: span 2;
  grid-row-end: span 2;
}

.transportButton {
  padding: 7px;
  height: 24px;
  width: 24px;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  justify-content:center;
  color: white;
  margin-right: 10px;
}
.playButton {
  background-color: var(--color-green-bright);
}
.pauseButton {
  background-color: var(--color-green-dark);
}
.stopButton {
  background-color: var(--color-purple-bright);
}

.tempoBox {
  display:flex;
  align-items:center;
  justify-content: center;
  margin-left: 48px;
}
.tempoBox span {
  /*margin-top: -8px;*/
}
.stepper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  max-height: 30px;
  margin-bottom: 10px;
}

.stepper svg {
  width: 30px;
  height: 30px;
}

.stepper a {
  display: inline-flex;
  align-items: end;
  max-height: 30px;
}

.stepper path:hover {
  color: var(--color-yellow-bright);
}

.stepButton {
  fill: var(--color-green-bright);
  margin-top: 10px;
  height: 30px;
  width: 30px;
}

.stepButton_disabled {
  fill: var(--color-yellow-dark);
}

.stepInput {
  box-sizing: border-box;
  height: 31px;
  width: 45px;
  text-align: center;
  font-size: 1em;
  border:0;
  margin-bottom: 4px;
  position: relative;
  top: 2px;
}
.transport {
  display: inline-flex;
  align-items:center;
}
.logo {
  width: 86px;
  position:absolute;
  right:45px;
}

@media only screen and (max-width: 900px) {
  body{
    max-width: 100vw;
    overflow-x: hidden;
  }
  .wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 84px;
    grid-template-rows: 50px 30px 1fr;
    grid-template-areas:
            "tran tran tran bran"
            "stab stab ctab ctab"
            "cont cont cont cont";
    min-height: 100vh;
    max-width: 100vw;
  }

  .content {
    grid-column: span 4;
  }

  .branding {
    margin-right: 10px;
  }

  .tempoBox {
    margin-left: 10px;
  }

  .transportButton {
    padding: 2px;
    margin-right:1px;
  }

  .tab {
    text-orientation: upright;
    writing-mode: horizontal-tb;
    width: 100%;
    text-align:center;
  }

  .tab_selected {
    border-bottom: 5px solid var(--color-purple-bright);
    border-right: 0;
  }
}