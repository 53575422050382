.Oscillator {
    display: grid;
    grid-template-areas:
        "ttl"
        "wav"
        "mix"
        "oct";
    grid-template-rows: 50px 120px 210px 100px;
    align-items: start;
    justify-items: center;
    text-align: center;
}

.ttl {
    grid-area: ttl;
}

.wav {
    grid-area: wav;
}

.mix {
    grid-area: mix;
}

.oct {
    grid-area: oct;
}

.wav input {
    font-size: 2rem;
    font-weight: bold;
    padding-bottom: 8px;
    letter-spacing: -2px;
    width: 50px;
    height: 40px;
}

.wav a {
    max-height: 40px;
}

.wav svg {
    height: 40px;
    width: 40px;
}