@import url(https://fonts.googleapis.com/css?family=Space+Mono:700&subset=latin-ext);
:root {
  --color-yellow-dark: #d1b00c;
  --color-purple-bright: #c20097;
  --color-purple-dark: #750c5e;
  --color-green-bright: #0e8f8b;
  --color-green-dark: #044f4d;
  --color-yellow-bright: #ffd507;
}

html {
  box-sizing: border-box;
}

body {
  background-color: #d1b00c;
  background-color: var(--color-yellow-dark);
  color:white;
  font-family: 'Space Mono', monospace;
  font-size: 1em;
  margin: 0;
}

a {
  text-decoration: none;
}

.wrapper {
  display: grid;
  grid-template-rows: 50px 1fr 1fr;
  grid-template-columns: 50px 1fr 1fr;
  grid-template-areas:
          "   . tran bran"
          "stab cont cont"
          "ctab cont cont";
  height: 100vh;
  max-width: 100vw;
}

.transport {
  grid-area: tran;
}

.branding {
  grid-area: bran;
  text-align: end;
  margin-right: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.branding img {
  height: 100%;
}

.tab {
  -webkit-text-orientation: sideways;
          text-orientation: sideways;
  -webkit-writing-mode: vertical-lr;
      -ms-writing-mode: tb-lr;
          writing-mode: vertical-lr;
  height: 100%;
  text-align:center;
  margin-left: auto;
}
.tab a {
  color: white;
}
.tab a:hover {
  color: #c20097;
  color: var(--color-purple-bright);
}

.panel_title {
  color: #c20097;
  color: var(--color-purple-bright);
}

.sequencer {
  grid-area: stab;
}

.controls {
  grid-area: ctab;
}

.tab_selected {
  border-right: 5px solid #c20097;
  border-right: 5px solid var(--color-purple-bright);
}
.tab_selected a {
  color: #c20097;
  color: var(--color-purple-bright);
}

.content {
  grid-area: cont;
  grid-column-end: span 2;
  grid-row-end: span 2;
}

.transportButton {
  padding: 7px;
  height: 24px;
  width: 24px;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  justify-content:center;
  color: white;
  margin-right: 10px;
}
.playButton {
  background-color: #0e8f8b;
  background-color: var(--color-green-bright);
}
.pauseButton {
  background-color: #044f4d;
  background-color: var(--color-green-dark);
}
.stopButton {
  background-color: #c20097;
  background-color: var(--color-purple-bright);
}

.tempoBox {
  display:flex;
  align-items:center;
  justify-content: center;
  margin-left: 48px;
}
.tempoBox span {
  /*margin-top: -8px;*/
}
.stepper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  max-height: 30px;
  margin-bottom: 10px;
}

.stepper svg {
  width: 30px;
  height: 30px;
}

.stepper a {
  display: inline-flex;
  align-items: end;
  max-height: 30px;
}

.stepper path:hover {
  color: #ffd507;
  color: var(--color-yellow-bright);
}

.stepButton {
  fill: #0e8f8b;
  fill: var(--color-green-bright);
  margin-top: 10px;
  height: 30px;
  width: 30px;
}

.stepButton_disabled {
  fill: #d1b00c;
  fill: var(--color-yellow-dark);
}

.stepInput {
  box-sizing: border-box;
  height: 31px;
  width: 45px;
  text-align: center;
  font-size: 1em;
  border:0;
  margin-bottom: 4px;
  position: relative;
  top: 2px;
}
.transport {
  display: inline-flex;
  align-items:center;
}
.logo {
  width: 86px;
  position:absolute;
  right:45px;
}

@media only screen and (max-width: 900px) {
  body{
    max-width: 100vw;
    overflow-x: hidden;
  }
  .wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 84px;
    grid-template-rows: 50px 30px 1fr;
    grid-template-areas:
            "tran tran tran bran"
            "stab stab ctab ctab"
            "cont cont cont cont";
    min-height: 100vh;
    max-width: 100vw;
  }

  .content {
    grid-column: span 4;
  }

  .branding {
    margin-right: 10px;
  }

  .tempoBox {
    margin-left: 10px;
  }

  .transportButton {
    padding: 2px;
    margin-right:1px;
  }

  .tab {
    -webkit-text-orientation: upright;
            text-orientation: upright;
    -webkit-writing-mode: horizontal-tb;
        -ms-writing-mode: lr-tb;
            writing-mode: horizontal-tb;
    width: 100%;
    text-align:center;
  }

  .tab_selected {
    border-bottom: 5px solid #c20097;
    border-bottom: 5px solid var(--color-purple-bright);
    border-right: 0;
  }
}

.Euclid {
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 1fr 3fr;
    grid-template-areas: "ring divider";
    min-height: 100%;
    justify-items: center;
    align-items: center;
}

.ring {
    grid-area: ring;
    margin-left: 10px;
}

.divider {
    grid-area: divider;
    display: grid;
    grid-template-areas:
        "track_head note_head onset_head offset_head"
        "t1 n1 on1 off1"
        "t2 n2 on2 off2"
        "t3 n3 on3 off3"
        "t4 n4 on4 off4";
    grid-auto-columns: 1fr;
    justify-items: center;
    align-items: center;
    grid-gap: 12px;
    font-size: 1.5em;
}

.Euclid path:hover {
    fill: var(--color-yellow-bright);
    cursor: pointer;
}
.Euclid #ring_svg {
    width: 44vw;
}
.oddRing {
    fill: var(--color-green-bright);
    stroke: #000000;
    stroke-width: 0;
    stroke-miterlimit: 10;
}

.evenRing {
    fill: var(--color-green-dark);
    stroke: #000000;
    stroke-width: 0;
    stroke-miterlimit: 10;
}

.stepOn{
    fill: var(--color-purple-bright);
    stroke: var(--color-purple-dark);
    stroke-width: 1px;
}

.stepActive {
    fill: var(--color-yellow-bright);
}

@media only screen and (max-width: 900px) {

    .Euclid {
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 100%;
        grid-template-areas: "ring" "divider";
        justify-items: center;
        align-items: start;
        max-width: 100vw;
        min-height: 100vh;
    }

    .ring {
        margin-left: 0;
        margin-top: 10px;
    }

    .Euclid #ring_svg {
        width: 100%;
        height: 325px;
    }

    .divider {
        grid-area: divider;
        display: grid;
        grid-template-areas:
                "track_head note_head onset_head offset_head"
                "t1 n1 on1 off1"
                "t2 n2 on2 off2"
                "t3 n3 on3 off3"
                "t4 n4 on4 off4";
        grid-template-columns: 0 1fr 1fr 1fr;
        justify-items: center;
        align-items: center;
        grid-gap: 1px;

    }

    .track {
        visibility: hidden;
    }
}
.ControlPanel {
    display: grid;
    grid-template-areas:
        "osc1 osc2 lpf adsr"
        "osc1 osc2 hpf adsr";
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    max-width: 100%;
    height: 90%;
    justify-items: center;
    align-items: start;
}

.osc1 {
    grid-area: osc1;
}

.osc2 {
    grid-area: osc2;
}

.lpf {
    grid-area: lpf;
}

.hpf {
    grid-area: hpf;
}

.adsr {
    grid-area: adsr;
}

@media only screen and (max-width: 900px) {
    .ControlPanel {
        grid-template-areas:
                "osc1 osc2"
                "lpf  hpf"
                "adsr adsr";
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 250px 1fr;
        max-width: 100%;
        min-height: 100%;
    }
}
.Oscillator {
    display: grid;
    grid-template-areas:
        "ttl"
        "wav"
        "mix"
        "oct";
    grid-template-rows: 50px 120px 210px 100px;
    align-items: start;
    justify-items: center;
    text-align: center;
}

.ttl {
    grid-area: ttl;
}

.wav {
    grid-area: wav;
}

.mix {
    grid-area: mix;
}

.oct {
    grid-area: oct;
}

.wav input {
    font-size: 2rem;
    font-weight: bold;
    padding-bottom: 8px;
    letter-spacing: -2px;
    width: 50px;
    height: 40px;
}

.wav a {
    max-height: 40px;
}

.wav svg {
    height: 40px;
    width: 40px;
}
.Filter {
    display: grid;
    grid-template-areas:
        "ttl ttl"
        "fre ."
        ". pea";
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 50px 80px 50px;
    text-align: center;
}

.ttl {
    grid-area: ttl;
    justify-self: center;
}

.freq {
    grid-area: fre;
    justify-self: center;
}

.peak {
    grid-area: pea;
    justify-self: center;
}
.ADSR {
    display: grid;
    grid-template-areas:
        "a ."
        ". d"
        "s ."
        ". r";
    grid-template-columns: 100px 100px;
    grid-template-rows: 140px 140px 140px 140px;
    text-align: center;
}

.attack {
    grid-area: a;
    justify-self: center;
}

.decay {
    grid-area: d;
    justify-self: center;
}

.sustain {
    grid-area: s;
    justify-self: center;
}

.release {
    grid-area: r;
    justify-self: center;
}

@media only screen and (max-width: 900px) {
    .ADSR {
        grid-template-areas:
                "a . s ."
                ". d . r";
        grid-template-columns: 100px 100px 100px 100px;
        grid-template-rows: 140px 140px;
    }
}
